import { useEffect, useRef } from "react";
import { useStaticQuery, graphql } from "gatsby";
import * as R from "ramda";
import AOS from "aos";
// import { useChartData } from "../../common/ChartData.jsx";
import {
  extractBanner,
  extractRichText,
  extractDatasetInfo,
} from "../../common/contentful-extract.jsx";
import { scrollToElement } from "../../common/scrollHelpers.js";
import Container100 from "../Container/Container100.jsx";
import Banner from "../Banner/Banner.jsx";
import Page from "../Page/Page.jsx";
import Section from "../Section/Section.jsx";
import ChartContent from "../ChartContent/ChartContent.jsx";

import DepartmentListing from "../DepartmentListing/DepartmentListing.jsx";

import Svg from "../Svg/Svg.jsx";

import "aos/dist/aos.css";
import "./data-explorer-page.scss";
// import "../ChartContent/chart-content.scss";

const DataExplorerPage = () => {
  const contentfulData = useStaticQuery(query);
  const parsedData = R.compose(
    R.evolve({
      headerBanner: extractBanner,
      descriptionSectionTitle: extractRichText,
      descriptionSectionSubtitle: extractRichText,
      ctaBanner: extractBanner,
      bottomRichText: extractRichText,
      lineChartTitle: extractRichText,
      datasetInfo: extractDatasetInfo,
      trendTableTitle: extractRichText,
      departmentListingSectionText: extractRichText,
    }),
    R.defaultTo({}),
    R.prop("contentfulDataExplorerPage"),
  )(contentfulData);

  const {
    headerBanner,
    descriptionSectionTitle,
    descriptionSectionSubtitle,
    ctaBanner,
    datasetInfo,
    bottomRichText,
    lastAnalyzed,
    lineChartTitle,
    trendTableTitle,
    departmentListingSectionText,
  } = parsedData;

  const formattedFootnotes =
    parsedData.footnotes &&
    parsedData.footnotes.map((x) => extractRichText(x.footnoteItem));

  const deptList = R.pathOr(
    [],
    ["allContentfulDepartmentPage", "nodes"],
    contentfulData,
  );
  const ChartContentRef = useRef();

  //banner currently has an overlay that causes the color to look much to dark
  headerBanner.overrideOverlay = true;

  useEffect(() => {
    // https://github.com/michalsnik/aos
    // initialize animation on scroll when the page loads
    AOS.init({
      offset: 100,
      duration: 1000,
      once: true,
    });
  }, []);

  return (
    <Page
      title="Data Explorer"
      highlightLogo={true}
      topHeroBanner={headerBanner}
    >
      <Banner {...headerBanner} className="data-explorer__hero-banner">
        <div className="data-explorer__scroll">
          <button
            type="button"
            title="Scroll"
            onClick={() => {
              if (ChartContentRef.current) {
                scrollToElement(ChartContentRef.current);
              }
            }}
          >
            <Svg id="chev-down" />
          </button>
        </div>
      </Banner>
      <Container100 className="" data-aos="fade-up">
        <Section
          aos={true}
          className="data-explorer__section data-explorer__wrapper"
          theme="light"
          title={descriptionSectionTitle}
          subtitle={descriptionSectionSubtitle}
          containerModifier="section__container--b-0"
          ref={ChartContentRef}
        />
        {/* TODO: Add indicator setup to contentful */}
        <ChartContent
          chartProps={{
            page: "data-explorer",
            datasetInfo,
            lastAnalyzed,
            lineChartTitle,
            trendTableTitle,
            defaultIndicator: 300,
            formattedFootnotes,
            indicatorItems: [
              { id: 300, value: "Number of incidents" },
              { id: 200, value: "Number of individuals affected" },
              {
                id: 100,
                value: "Individuals affected per 1000 known offenses",
              },
              { id: 900, value: "Individuals affected per 1000 capita" },
            ],
          }}
          defaultBreakdownLabel={"City"}
        />
      </Container100>

      <DepartmentListing
        deptListText={departmentListingSectionText}
        deptList={deptList}
      />
      <Banner {...ctaBanner} />
      <Section
        aos={true}
        className="data-explorer__section data-explorer__section--methodology"
        theme="light"
      >
        {bottomRichText && (
          <Container100 className="data-explorer__bottom-rich-text">
            {bottomRichText}
          </Container100>
        )}
      </Section>
    </Page>
  );
};

export default DataExplorerPage;

const query = graphql`
  {
    allContentfulDepartmentPage {
      nodes {
        id
        department {
          id
          name
          state
        }
        url
      }
    }
    contentfulDataExplorerPage {
      headerBanner {
        backgroundImage {
          fluid {
            aspectRatio
            base64
            sizes
            src
            srcSet
            srcSetWebp
            srcWebp
          }
          id
        }
        backgroundSize
        title {
          raw
        }
        subtitle {
          raw
        }
        content {
          raw
        }
        link {
          ...Link
        }
      }
      descriptionSectionTitle {
        raw
      }
      descriptionSectionSubtitle {
        raw
        references {
          ...Link
        }
      }
      ctaBanner {
        backgroundImage {
          fluid {
            srcWebp
            srcSetWebp
            srcSet
            src
            sizes
            base64
            aspectRatio
          }
          file {
            url
          }
        }
        backgroundSize
        title {
          raw
        }
        subtitle {
          raw
        }
        link {
          ...Link
        }
      }
      lineChartTitle {
        raw
      }
      footnotes {
        footnoteItem {
          raw
        }
      }
      lastAnalyzed(locale: "en-US", formatString: "MMMM DD, yyyy")
      trendTableTitle {
        raw
      }
      bottomRichText {
        raw
        references {
          ...Link
          ... on ContentfulAsset {
            contentful_id
            __typename
            title
            description
            fixed {
              src
              srcSet
              width
              height
              srcSetWebp
              srcWebp
              tracedSVG
            }
            fluid {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
            file {
              url
              fileName
              details {
                size
                image {
                  height
                  width
                }
              }
            }
          }
        }
      }
      departmentListingSectionText {
        raw
      }
      datasetInfo {
        buttonText
        content {
          raw
        }
      }
    }
  }
`;
