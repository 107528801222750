import DataExplorerPage from "../components/DataExplorerPage/DataExplorerPage.jsx";
import { ChartDataProvider } from "../common/ChartData.jsx";

const GatsbyDataExplorerPage = (props) => (
  <ChartDataProvider>
    <DataExplorerPage />
  </ChartDataProvider>
);

export default GatsbyDataExplorerPage;
